<template src="./explore.html"></template>
<style lang="scss" src="./explore.scss" scoped></style>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapGetters, mapActions } from 'vuex'
import { companyGetters } from '@/store/modules/company/routines'
import { opportunityRoutine, opportunityGetters } from '@product/store/modules/opportunity/routines'
import { matchesTypeFilterGetters } from '@/store/modules/matchesTypeFilter/routines'
import { productTypeOptionsDic, loanSubCategoryTypeOptions, equitySubCategoryTypeOptions, productSubCategoryTypeOptions, amplitudeEvents } from '@/utils/constants'
import FieldError from '@/components/forms/FieldError'
import { authGetters } from '@/store/modules/auth/routines'
import ProductItem from '@/components/ProductItem'
import CurrencySymbol from '@/components/CurrencySymbol'
import { getLockedProducts } from '@/utils/utils'

export default {
  name: 'FundingExplore',
  components: {
    CurrencySymbol,
    ValidationObserver,
    ValidationProvider,
    FieldError,
    ProductItem
  },
  data() {
    return {
      filters: {
        productType: this.$route.query.productType || '',
        subCategoryType: this.$route.query.subCategoryType || '',
        sizeInput: this.$route.query.sizeInput || '',
        size: Number(this.$route.query.size) || 0
      },
      shouldShowAll: false,
      currentTab: Number(this.$route.query.currentTab) || 0,
      loansLocked: true,
      equityLocked: true,
      grantsLocked: true
    }
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID,
      opportunityList: opportunityGetters.OPPORTUNITY_LIST,
      matchesTypeFilterSelected: matchesTypeFilterGetters.MATCHES_TYPE_FILTER_SELECTED,
      user: authGetters.USER
    }),
    productListMain() {
      if (!this.opportunityList || !this.opportunityList.length) return []
      return this.opportunityList
        .filter(product => {
          if (this.filters.size === 0) return true
          return this.filters.size >= product.maxSize
        })
        .sort((a, b) => b.maxSize - a.maxSize)
    },
    productListFilteredByType() {
      return this.productListMain
        .filter(product => (this.filters.productType ? product.type === this.filters.productType : true))
        .filter(product => (this.filters.subCategoryType ? product.subcategory === this.filters.subCategoryType : true))
    },
    productListCovidFilteredByType() {
      return this.productListFilteredByType.filter(product => product.isCovid19)
    },
    productListNonCovidFilteredByType() {
      return this.productListFilteredByType.filter(product => !product.isCovid19)
    },
    liveOfferList() {
      return this.productListMain.filter(product => product.type === 'LiveOffer')
    },
    topLiveOffers() {
      return this.liveOfferList.reduce((acc, liveOffer) => {
        const { providerName } = liveOffer
        if (!Object.prototype.hasOwnProperty.call(acc, providerName) || liveOffer.maxSize > acc[providerName].maxSize) {
          acc[providerName] = liveOffer
        }
        return acc
      }, {})
    },
    liveOfferListLimited() {
      const limit = 3
      const topLiveOffersList = Object.values(this.topLiveOffers)
      if (topLiveOffersList.length >= limit) return topLiveOffersList
      const otherLiveOffersList = this.liveOfferList
        .filter(liveOffer => topLiveOffersList.findIndex(lo => lo.opportunityId === liveOffer.opportunityId) === -1)
        .slice(0, limit - topLiveOffersList.length)
      return [...topLiveOffersList, ...otherLiveOffersList].sort((a, b) => (a.providerName || '').localeCompare(b.providerName || ''))
    },
    loanList() {
      return this.productListMain.filter(product => product.type === 'Loan')
    },
    loanListLimited() {
      return this.loanList.slice(0, 4)
    },
    loanListCovid() {
      return this.loanList.filter(product => product.isCovid19)
    },
    loanListNonCovid() {
      return this.loanList.filter(product => !product.isCovid19)
    },
    equityList() {
      return this.productListMain.filter(product => product.type === 'Equity')
    },
    equityListLimited() {
      return this.equityList.slice(0, 2)
    },
    grantList() {
      return this.productListMain.filter(product => product.type === 'Grant')
    },
    grantListLimited() {
      return this.grantList
    },
    productTypeOptions() {
      return [
        ...(this.liveOfferList ? [productTypeOptionsDic.LiveOffer] : []),
        ...(this.loanList ? [productTypeOptionsDic.Loan] : []),
        ...(this.equityList ? [productTypeOptionsDic.Equity] : []),
        ...(this.grantList ? [productTypeOptionsDic.Grant] : [])
      ]
    },
    subCategoryTypeOptions() {
      if (this.filters.productType === 'Loan' && this.filters.productType === 'Equity') {
        return productSubCategoryTypeOptions
      } else if (this.filters.productType === 'Loan') {
        return loanSubCategoryTypeOptions
      } else if (this.filters.productType === 'Equity') {
        return equitySubCategoryTypeOptions
      } else {
        return []
      }
    }
  },
  methods: {
    ...mapActions({
      requestOpportunityList: opportunityRoutine.TRIGGER
    }),
    setActiveTab(index) {
      if (index === 0 && this.loansLocked && !this.this.$config.whitelabel.journey.classicJourneyEnabled) {
        this.$router.push({ name: 'product-loan' })
      } else if (index === 1 && this.equityLocked && !this.this.$config.whitelabel.journey.classicJourneyEnabled) {
        this.$router.push({ name: 'product-equity' })
      } else if (index === 2 && this.grantsLocked && !this.this.$config.whitelabel.journey.classicJourneyEnabled) {
        this.$router.push({ name: 'product-grant' })
      } else {
        this.currentTab = index
      }
    },
    fillOpportunityList() {
      return this.requestOpportunityList({ companyId: this.companyId })
    },
    amountChangeHandler(validate) {
      this.$nextTick(() => {
        validate().then(result => {
          if (!result) return
          this.filters.size = this.filters.sizeInput || 0
        })
      })
    },
    resetAmount(validate) {
      this.filters.sizeInput = ''
      return this.amountChangeHandler(validate)
    },
    trackLiveOfferClick(product) {
      this.$ma.trackEvent({
        eventType: 'Live Offer Eligibility Tool opened',
        eventProperties: {
          'Product ID': product.opportunityId,
          'Product Name': product.productName,
          'Product Vendor': product.providerName
        }
      })
    }
  },
  created() {
    this.shouldShowAll = this.$route.params.showAll
    this.fillOpportunityList()

    if (this.user && !this.$config.whitelabel.journey.classicJourneyEnabled) {
      const lockedProducts = getLockedProducts(this.user.goalsCompleted)
      this.loansLocked = lockedProducts.loansLocked
      this.equityLocked = lockedProducts.equityLocked
      this.grantsLocked = lockedProducts.grantsLocked
    }
  },
  mounted() {
    if (this.$ma) {
      this.$ma.trackEvent({ eventType: amplitudeEvents.Matches.MATCHES_PAGE_LOADS })
    }
  },
  watch: {
    subCategoryTypeOptions(newSubCategoryTypeOptions) {
      if (this.filters.subCategoryType === '') return
      const index = newSubCategoryTypeOptions.findIndex(subCategory => subCategory.value === this.filters.subCategoryType)
      if (index === -1) {
        this.filters.subCategoryType = ''
      }
    },
    filters: {
      deep: true,
      handler(newFilters) {
        const query = Object.entries(newFilters).reduce((acc, [key, value]) => {
          if (value) {
            acc[key] = String(value)
          }
          return acc
        }, {})
        this.$router.replace({ query })
      }
    }
  }
}
</script>
